/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      // CHECK IF MOBILE 
      var mobile = new WSI.Mobile();
      var isMobile = mobile.Detect();

       // COOKIE DATA 
       var expires = '';
      function createCookie(name,value,days) {
          if (days) {
              var date = new Date();
              date.setTime(date.getTime()+(days*24*60*60*1000));
              expires = "; expires="+date.toGMTString();
          } else { expires = ""; }
          document.cookie = name+"="+value+expires+"; path=/";
      }
      function readCookie(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for(var i=0;i < ca.length;i++) {
              var c = ca[i];
              while (c.charAt(0)===' ') {c = c.substring(1,c.length);}
              if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length,c.length); }
          }
          return null;
      }
      function eraseCookie(name) {
          createCookie(name,"",-1);
      }

      // IC PROFILE COUNTRY SELECTION 
      $('.ic-countries li').on('click',function () {
        var lang = $(this).data('lang');
        createCookie('ic-lang',lang,7);
        location.reload();
      });

      // 
      $("div.lazy,img.lazy").lazyload({
          effect : "fadeIn"
      });

      //
      $( '.grid' ).masonry();

       $('[data-toggle="popover"]').popover({ trigger: "hover", placement:'top' });
		  
      // MAIN BODY LOADER 
		  // $('body').imagesLoaded({ background: '.image.background' }, function() {
			 //  // images have loaded
			 //  if(!$('body').is('.loaded')){
				//   $('body').addClass("loaded");
				//   //$('.banner, .wrap').fadeIn(600);
				//   $('.loader').fadeOut(600, function(){
				// 	  if(!$('body').is('.news')){
				// 	  	  $(this).remove(); // remove the loader div if we don't need it
    //             $('.floating-ballons').addClass('float');
				// 	  }
				//   });
			 //  }
		  // });
    
      setTimeout(function () {
          $('body').addClass("loaded");
          //$('.banner, .wrap').fadeIn(600);
          $('.partner-logos').css({'display':'block'});
          $('.loader').fadeOut(600, function(){
           if(!$('body').is('.news')){
               $(this).remove(); // remove the loader div if we don't need it
                $('.floating-ballons').addClass('float');
           }
         });
      },100);

      // open resource modal
       $("#resource-modal").modal('show');

       $('#resource-form .element').on('click',function () {
          $(this).find('#365430787-error').remove();
           $(this).find('input').focus();
       });

       $('#resource-form #submit_button').on('click',function (e) {
          
          var fname = $('#365429763').val();
          var lname = $('#365430787').val();
          var email = $('#365431811').val();
          var phone = $('#365436931').val();
          
          var url  = window.location.href;
          $("#page-url").val(url);
          $("#page-id").val($('.page-id').data('page'));
          var error = '<label id="365430787-error" class="error" style="display: inline;">Please provide a valid email.</label>';
          var valid = true;

          $('#365430787-error').remove();
          if(fname == ''){
            //$('#365429763').parent().prepend(error);
            $('#365429763').addClass('error'); 
            valid = false;
          }
          if(lname == ''){
           // $('#365430787').parent().prepend(error);
            $('#365430787').addClass('error'); 
            valid = false;
          }
          if(phone == ''){
           // $('#365430787').parent().prepend(error);
            $('#365436931').addClass('error'); 
            valid = false;
          }
           
          if(email == '' || !isEmail(email)){
            $('#365431811').parent().append(error);
            $('#365431811').addClass('error'); 
            valid = false;
          }

          if(!valid){
            e.preventDefault();
          } else {
            $('#resource-form').css({'display':'none'});
            $('.form-thank-you').css({'disply':'block'});
          }
       });

      // CLOSE RESOURCE MODAL 
      $('.close-resource').on('click',function () {
         $("#resource-modal").modal('hide');
      });


      // IC PROFILE CONTACT FORM 
      $('#ic-contact-form #submit_button').on('click',function (e) {
          var fname = $('#365429763').val();
          var lname = $('#365430787').val();
          var email = $('#365431811').val();
          var phone = $('#365436931').val();
          var url  = window.location.href;
          var dStr = '&icemail='+$('.ic-main-email').html();
          $("#page-url").val(url);
          $("#page-id").val($('.page-id').data('page'));
          var error = '<label id="365430787-error" class="error" style="display: inline;">Please enter a valid email</label>';
          var valid = true;
          $('#365430787-error').remove();
          if(fname == ''){
            $('#365429763').addClass('error');
            //$('#365429763').parent().append(error);
            valid = false;
          }
          if(lname == ''){
            $('#365430787').addClass('error');
            valid = false;
          }
          
          if(phone == '' || !validatePhone(phone)){
            $('#365436931').addClass('error');
            valid = false;
          }
           
          if(email == '' || !isEmail(email)){
            $('#365431811').addClass('error');
            $('#365431811').parent().append(error);
            valid = false;
          }

          if(!valid){
            e.preventDefault();
          } else {
            $('.form-wrapper').css({'opacity':0});
            $('.ic-thankyou-msg').css({'display':'block'});
            // open thank you. 

          }
       });
      //

      $('#field_472327171').on('click',function(){
        if(document.getElementById('472327171-8').checked) {
            $(".custom-check").addClass('checked');
        } else {
            $(".custom-check").removeClass('checked');
        }
      });


      // 
      $(document).on('click', '[data-toggle="custom-lightbox"]', function(event) {
          event.preventDefault();
          var img = $(this).find('img').attr('src');
          $('#light-box-modal').modal('show');
          $('.light-box-image').html('<img src="'+img+'"/>');
          $('html,body').addClass('no-scroll');
      });

      $("#light-box-modal").on("hidden.bs.modal", function () {
          $('html,body').removeClass('no-scroll');
      });

      // set focus to search 
      
      $("#search-modal").on("show.bs.modal", function () {
         $('#header-search-imput').focus();
      });


      //
      $(".light-box img").each(function() {
        var src = $(this).addClass('image').attr('src');
        var a = $('<div data-toggle="custom-lightbox"><div class="custom-lightbox"></div></div>').attr('href', src);
        $(this).wrap(a);
      });
     
     // Chat box listener 
     $('.vector-help, .close-chat-box').click(function(e){
        e.preventDefault();
        if($('.chat-box').hasClass('open')){
          $('.chat-box').removeClass('open');
        }else{
          $('.chat-box').addClass('open');
        }
        //
        return false;
      });

     // Burger button  
     // MOBILE BUTTON 
     $('.burger, .mobile-nav-close').click(function(e){
        e.preventDefault();
        if($('.mobile-primary').hasClass('open')){
          $('.mobile-primary').removeClass('open');
          $('html,body').removeClass('no-scroll');
          $('.child-pages,.first-child,.second-child').slideUp();
          $('.id-*').slideUp();
        }else{
          $('.mobile-primary').addClass('open');
          $('html,body').addClass('no-scroll');
        }
        //
        return false;
      });

     $('.mobile-primary .identifier').on('click',function (e) {
       e.preventDefault();
       var nav =  $(this).parent().parent().find('.child-pages');
       if($(nav).length){
           if($(nav).is(':visible')){
             $(nav).stop(true,true).slideUp();
             $(this).html('+');
           } else {
             $(nav).stop(true,true).slideDown();
             $(this).html('-');
           }
            return false;
         }
     });

     $('.child-identifier').on('click',function (e) {
       e.preventDefault();
        
       var childID = $(this).data('id');
       var nav =  $('.id-'+childID);
       if($(nav).length){
           if($(nav).is(':visible')){
             $(nav).stop(true,true).slideUp();
             $(this).html('+');
           } else {
             $(nav).stop(true,true).slideDown();
             $(this).html('-');
           }
            return false;
         }
     });

     $('.child-pages a').on('click',function (e) {
        e.stopPropagation(); 
     });

     
      $('[data-lparent]').each(function () {
          var selected = $(this).find('a').hasClass('selected');
          var firstChild = $(this).hasClass('first-child');
          var secondChild = $(this).hasClass('second-child');
          var grandChild = $(this).hasClass('grand-child');
          var p = $(this).attr('data-lparent');
          if(selected){
             if(grandChild){
                var fParent = $(this).data('firstparent');
                var nParent = $(this).data('lparent');
                $('.id-'+fParent).show();
                $('.id-'+nParent).show();
             }

            if(secondChild ){
                var nParent = $(this).data('lparent');
            }
          }
      });

  

      // HEADER STICKY 
      $(window).scroll(function (event) {
          var top = $(window).scrollTop();
          if(top >= 100){
            $('.header-sticky').addClass('header-sticky-open');
            $('.nav-backdrop').addClass('open');
            $('.header-logo a').addClass('open');
            $('.sub-nav').addClass('open');
            $('.social-nav').addClass('open');
            $('.social-nav .burger').css({'display':'inline-block'});
          }else{
            $('.header-sticky').removeClass('header-sticky-open');
            $('.nav-backdrop').removeClass('open');
            $('.header-logo a').removeClass('open');
            $('.sub-nav').removeClass('open');
            $('.social-nav').removeClass('open');
           $('.social-nav .burger').css({'display':'none'});
          }
      });


      // Global Footer 
      $('.partners').css({'display':'block'});
      $('#partner-list').slick({
            infinite: true,
            prevArrow: $('.glyphicon-chevron-left'),
            nextArrow: $('.glyphicon-chevron-right'),
            slidesToShow: 4,
            slidesToScroll: 4,
            centerMode: false,
            padding: '20px',
            autoplay: true,
            autoplaySpeed: 5000,
            accessibility:false,
            responsive: [
                {
                  breakpoint: 999,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                  }
                }
            ]             
        });

      // IC PROFILE FUNCTIONALITY 

      // Testimonial Slider 
      $('#testimonial-slider').slick({
          infinite: true,
          nextArrow: '',
          prevArrow: '',
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          padding: '20px',
          dots: true,
          adaptiveHeight: true,
          accessibility:false,
          //variableWidth: true,
          customPaging: function(slider, i) { 
             return '';
          },
          responsive: [
              {
                breakpoint: 999,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              }
          ]             
      });

      // IC PROFILE SCROLL TO FORM 
      // THIS ONLY FIRES IF THE USER HAS 
      // ENTERNED A URL PATH IN THE ADMIN> 
      $('.scroll-to-form').on('click',function () {
          $('html, body').animate({
              scrollTop: $("#ic-profile-form").offset().top - 50
          }, 600);
      });
		   
      // 
      $('.read-more-btn').on('click', function () {
          if($('.ic-description').hasClass('open-desc')){
            $('.ic-description').removeClass('open-desc');
          }else{
            $('.ic-description').addClass('open-desc');
          }
      });


      //
      $('.faqs li').on('click',function () {
        if($(this).find('p').is(':visible')){
          $(this).find('p').slideUp();
        } else {
          $(this).find('p').slideDown();
        }

      });

      /// MAKE NUMBERS ANIMATE WHEN SCROLLED INTO THE SCREEN 
      var fired = false;
      function fireNumbers() { 
          if(!fired){
            $('.percent-item .value').each( function () {
              
               var par = $(this).parent();
               var percent = $(par).data('offset');
               var bg = $(par).parent().find('.bg-grad');
               var width = $(bg).data('offset');
               var to = parseInt(width);
               
                $(this).counterUp({
                    from: 0,
                    to: to,
                    speed: 2000,
                    refreshInterval: 50,
                    onComplete: function(value) {
                     // fired = false;
                    },
                    onUpdate: function (value) {
                      // var offset = value - 20;
                      $(par).css({'left':value+'%'});
                      $(bg).css({'width':value+'%'});
                    }
                });
            });
          }
          
      }
      fireNumbers();

      // 
      $('.newsletter-submit').on('click',function(e){
          // e.preventDefault();
          // $('.mail-element').fadeOut();
          // $('.form-msg').fadeOut();
          // $(this).fadeOut();
      });

      //
      //video-modal
      var homeVideo = '';
      $('#video-modal').find('iframe').each(function(){
        var url = $(this).attr('src');
        homeVideo = url+'?rel=0&autoplay=1';
      });
      $('.video-play').on('click',function () {
        $("#video-modal").modal('show');
        $('#video-modal').find('iframe').attr('src',homeVideo);
      });
      $('.close-video').on('click',function () {
        $("#video-modal").modal('hide');
        $('#video-modal').find('iframe').attr('src','');
      });

      //
      var hash = window.location.hash;
      if(hash === '#survey'){
        $("#survey-modal").modal('show');
         $('html,body').addClass('no-scroll');
      }

      $("#survey-modal").on("hidden.bs.modal", function () {
          $('html,body').removeClass('no-scroll');
      });

      $('#survey').submit(function(e) {

        //prevent Default functionality
        e.preventDefault();

        //get the action-url of the form
        var actionurl = e.currentTarget.action;
        var data = $("#survey").serializeArray();

        // validate 
        var email = $("input#emailaddress").val();

        var valid = true; 
        // $('#survey input').each(function () {
        //   var sel = $(this).val();
        //    if(sel === ""){
        //     $(this).addClass('error');
        //     valid = false;
        //    } else {
        //     $(this).removeClass('error');
        //    }
        // });

        // $('#survey select').each(function () {
        //   var sel = $(this).val();
        //    if(sel === "Select"){
        //     $(this).addClass('error');
        //     valid = false;
        //    } else {
        //     $(this).removeClass('error');
        //    }
        // });

        if(!isEmail(email)){
          $("input#emailaddress").attr("placeholder","Please Enter a valid Email Address");
          valid = false;
        } 

        if(!valid){return;}

        var dStr = '&icemail='+$('.ic-main-email').html();
        for(var i =0; i < data.length; i++ ){
            
            var name = data[i]['name'].replace(":", "");
            name = name.replace("-", "");
            name = name.replace("-", "");
            name = name.replace(" ", "").toLowerCase();
            //console.log(name);
            dStr+='&'+name+'='+data[i]['value'];
        } 
        //do your own request an handle the results
        $.ajax({
                url: '/wp-content/themes/wsi/send-survey.php',
                type: 'POST',
                data: dStr,
                success: function(data) {
                   $("#survey").css({'display':'none'});
                   $(".survey-thank-you").css({'display':'block'});

                },
                error :function (msg) {
                console.log(msg);
                 // $("#survey-modal").modal('hide');
                }
        });

    });


    // VALID EMIAL 
    function isEmail(email) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    }

    // VALIDATE PHONE 
    function validatePhone(phone) {
            intRegex = /[0-9 -()+]+$/;
      if((phone.length < 6) || (!intRegex.test(phone))) {
        return false;
      } else {
        return true;
      }
  }

      // 
      // parallax ( MOVE TO HOME PAGE )
      if(!isMobile){ 
        var s = skrollr.init({
          forceHeight: false,
          smoothScrolling: true,
          render: function() {
             var container = $("#percetage-wrap").hasClass('centered');
             if(container && !fired ){
               // fireNumbers();
               fired = true;
             }
          }
        });
      }

      
      /******************* CONTENT MODAL *****************************/

      //
      // $('html,body').addClass('no-scroll');

      $('.modal-opener').on('click',function (e) {
        e.preventDefault();
         $("#content-modal").modal('show');
       //  $('html,body').addClass('no-scroll');
         var content = $(this).find('.modal-content-wrap').html();
         $('.modal-content-display').html('');
         $('.modal-content-display').html(content);

         var title = $(this).find('h4').html();
         var subTitle = $(this).find('h6').html();
         $('#content-modal .content-wrap h4').html(title);
         $('#content-modal .content-wrap h6').html(subTitle);

      });

      $('.modal-opener .linkedin a').on('click',function (e) {
         e.stopPropagation(); 
      });
      //



      $("#content-modal").on("hidden.bs.modal", function () {
          $('html,body').removeClass('no-scroll');
      });


    
      /************** COMPONENTS *******************/ 


      // Content Expander 
      $('.inner-content-wrap').each(function () {
        var height = parseInt($(this).css('height'));
        if(height < 90){
          $(this).parent().parent().find('.more-cta');
        }
      });
      $('.more-cta').on('click', function () {
        var expander = $(this).parent().find('.expand');
        var pHeight = parseInt($('.inner-text-wrap').css('height'));
        var grad = $(this).parent().find('.gradient-border');
        var more = $(this);

        console.log(pHeight + " $(expander).css('height') ::>>> " +$(expander).css('height') );
        if($(expander).hasClass('open')){
          $(expander).removeClass('open');
          $(grad).removeClass('expanded');
          $(more).html('+ More');
        }else {
          $(expander).addClass('open');
          $(grad).addClass('expanded');
          $(more).html('- More');
        }

        // if($(expander).css('height') === (pHeight + 'px')){
        //    $(expander).animate({'height':'95px'});
        //    $(grad).removeClass('expanded');
        //    $(more).html('+ More');
        // }else {
        //   $(expander).animate({'height':'auto'});
        //   $(grad).addClass('expanded');
        //   $(more).html('- More');
        // }
      }); 

      //
      $('.toggle-heading a').on('click',function (e) {
        e.preventDefault();
      });
       
      //
      $('.toggle-heading').on('click',function (e) {
         //console.log("CLICKED  " + $(this).find('a').hasClass('collapsed') );
         var div = $(this).find('a').data('parent');
        if($(this).find('a').hasClass('collapsed')){
          $(this).find('a').removeClass('collapsed');
          $(this).find('.accordion-arrow').removeClass('accordion-arrow-col');
          $(div).slideUp();
         
        }else{
          $(this).find('a').addClass('collapsed');
           $(this).find('.accordion-arrow').addClass('accordion-arrow-col');
          $(div).slideDown();
        }

      });
		 
		  
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page		  
		     
        // Testimonial Slider 
        $('#case-slider').slick({
            infinite: true,
            nextArrow: '',
            prevArrow: '',
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            padding: '20px',
            accessibility:false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 8000,
            customPaging : function(slider, i) {
                return '<a title="Case Dots" class="dot"><img alt="case dot image" src="/wp-content/themes/wsi/dist/images/dots.png"></a>';
            },            
        });
        
       
		  
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

         // Twitter Feed 
         var twitterFeed = new WSI.TwitterFeed();
        
        
      }
    },
     // Home page
    'case_studies': {
      init: function() {
        
        // Testimonial Slider 
        $('#case-slider').slick({
            infinite: true,
            nextArrow: '',
            prevArrow: '',
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            padding: '20px',
            dots: true,
            autoplay: true,
            autoplaySpeed: 8000,
            customPaging : function(slider, i) {
                return '<a title="Case Slider Dots" class="dot"><img alt="Case Slider Dot" src="/wp-content/themes/wsi/dist/images/dots.png"></a>';
            },
            responsive: [
                {
                  breakpoint: 999,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                  }
                }
            ]             
        });
        
        
         

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    //  
    // Home page
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
		  
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    // Blog page
    'blog': {
      init: function() {
         

        var win = $(window);
        var loading = false;
        var content = $(".grid");
        var POSTCOUNT = 1;
        var data = {};
        var mobile = new WSI.Mobile();
        var isMobile = mobile.Detect();
        function getMorePosts (cat,count) {
   
            if(!count || count ===''){
              POSTCOUNT+=1;
            } else {
              POSTCOUNT = count;
            }
            
            if(!cat ){ cat = -12;}
            data = { 'pageNumber': POSTCOUNT,'numPosts':6 ,'cat':cat };
              $.post('/wp-content/themes/wsi/lib/get-posts.php', data, function(returned) {
               var $elems = $(returned);
              // console.log(returned);
              loading = false;
               $('.grid').masonry().append( $elems ).masonry('appended', $elems, true ).masonry('layout');
               // $('.grid').masonry({  itemSelector: '.grid-item' });
              $( '.grid' ).masonry( 'reloadItems' );
              $( '.grid' ).masonry( 'layout' );
               setInterval(function(){
                $( '.grid' ).masonry( 'reloadItems' );
                $( '.grid' ).masonry( 'layout' );
                },500);
               
            });
        }

          /**************  BLOG  ************************/ 
          $(window).on('load', function(){
            $('.grid').masonry({
              // options
              itemSelector: '.grid-item'
            });
          });

          // LOAD MORE 
          $('.load-more-btn').on('click',function (e){
              e.preventDefault();
              getMorePosts();
          });

          // FLITER POSTS 
          $('.category-filters li').on('click',function (e){
              e.preventDefault();
              $('.category-filters li').removeClass('selected');
              $(this).addClass('selected');
              $('.grid').masonry( 'remove', $('.grid').find('.grid-item') );
              var value = $(this).data('category'); 
              getMorePosts(value,1);
          });
     
          var isResource = $('.category-filters').length;
          if(isResource && !isMobile){
            win.scroll(function() {
                  var content_offset = content.offset(); 
                  if(!loading && (win.scrollTop() + win.height()) > (content.scrollTop() + content.height() + content_offset.top)) {
                  loading = true;
                  POSTCOUNT++;
                  getMorePosts('',POSTCOUNT);
                  }
             });
          }



      
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    //  
    'resources': {
      init: function() {
       
         $(window).on('load', function(){
            $('.grid').masonry({
              // options
              itemSelector: '.grid-item'
            });
          });

       
      var win = $(window);
      var loading = false;
      var content = $(".grid");
      var POSTCOUNT = 1;
      var data = {};
      function getMoreResources (cat,count) {
          if(!count){
            POSTCOUNT+=1;
          } else {
            POSTCOUNT = count;
          }
          data = { 'pageNumber': POSTCOUNT,'numPosts':6 ,'cat':cat };
            $.post('/wp-content/themes/wsi/lib/get-resources.php', data, function(returned) {
             var $elems = $(returned);
             loading = false;
             $('.grid').masonry()
            .append( $elems )
            .masonry('appended', $elems );
             $('.grid').masonry({  itemSelector: '.grid-item' });
             
          });
      }  

      win.scroll(function() {
            var content_offset = content.offset(); 

            if(!loading && (win.scrollTop() + win.height()) > (content.scrollTop() + content.height() + content_offset.top)) {
            loading = true;
            POSTCOUNT++;
            getMoreResources('',POSTCOUNT);
            }
       });
      


		  	
         // LOAD MORE 
      $('.load-more-btn').off();
      $('.load-more-btn').on('click',function (e){
          e.preventDefault();
          getMoreResources();
      });

      // FLITER POSTS 
      $('.category-filters li').on('click',function (e){
          e.preventDefault();
          $('.category-filters li').removeClass('selected');
          $(this).addClass('selected');
          $('.grid').masonry( 'remove', $('.grid').find('.grid-item') );
          var value = $(this).data('category'); 
          getMoreResources(value,1);
      });

      

      //
      $totalCount = 0;
      $('.grid-item').each(function () {
        $totalCount++;
      });

    //  if($totalCount <= 6 ){
        $('.load-more-btn').hide();
    //  }


		  
			 
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
         
      //video-modal
      var homeVideo = '';
      $('#video-modal').find('iframe').each(function(){
        var url = $(this).attr('src');
        homeVideo = url+'?rel=0&autoplay=1';
        //console.log(url);
      });
      $('.inline-floater').on('click',function () {
        $("#video-modal").modal('show');
        $('#video-modal').find('iframe').attr('src',homeVideo);
      });
      $('.close-video').on('click',function () {
        $("#video-modal").modal('hide');
        $('#video-modal').find('iframe').attr('src','');
      });

        
	  
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
