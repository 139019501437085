/********************************************************************
  New Class Instance 
  WSI.Mobile Support
  Return false
  Paramaters false 
  Handles Mobile fucntionality : 
  1 ) Switches out all background images to "480x750" images. 
*********************************************************************/
(function () {
  
  'use strict'; 
  
  var Mobile = WSI.Mobile = (function ($) {
      
    function Mobile(){

     var scope = this;
     this.width = $(window).width();
     this.mobileWidth = 1025;
     if(this.width < this.mobileWidth){ 
       // this.SwitchBackgroundImages('mobile');
     }else{
       //this.SwitchBackgroundImages('desktop');
     }

     $(window).off('resized');
     $(window).resize(function() {
        if(this.resizeTO) {
          clearTimeout(this.resizeTO);
        }
        this.resizeTO = setTimeout(function() {
            $(this).trigger('resized');
        }, 500);
     });

     $(window).bind('resized', function() {
          scope.width = $(window).width();
          if(scope.width < scope.mobileWidth){
          //  scope.SwitchBackgroundImages('mobile');
          }
          if(scope.width > scope.mobileWidth){
           // scope.SwitchBackgroundImages('desktop');
          }
      });
 
      // OPEN MOBILE NAV 
      $('.burger').on('click',function () {
          if($('.nav-mobile-overlay').hasClass('open')){
             $('.nav-mobile-overlay').removeClass('open');
          }else{
             $('.nav-mobile-overlay').addClass('open');
          }
      });

      // CLOSE MOBILE NAV 
      $('.nav-mobile-overlay .close-btn').on('click',function () {
          $('.nav-mobile-overlay').removeClass('open');
      });

      $('.mobile-sub-nav-select h5,.mobile-sub-nav-select .dropper').on('click', function () {
        var list = $('.sub-list');
        if($(list).hasClass('open')){
          $('.sub-list').removeClass('open');
          $('.dropper .glyphicon').addClass('glyphicon-triangle-bottom');
          $('.dropper .glyphicon').removeClass('glyphicon-triangle-top');
        }else {
          $('.sub-list').addClass('open');
          $('.dropper .glyphicon').removeClass('glyphicon-triangle-bottom');
          $('.dropper .glyphicon').addClass('glyphicon-triangle-top');
        }

      });

    }
  
    Mobile.prototype = {

      // switch out background images for mobile 
      SwitchBackgroundImages : function  (type) {
          $('.image.background').each(function () {
           var bg = $(this).data(type);
          // console.log( "CHANGING IMAGE " + bg );
           if(bg){$(this).css({'background-image':'url('+bg+')'});}
          });
      },

      Detect : function () {
         var bool = false; 
         if(this.width < this.mobileWidth){ 
           bool = true;
         }
         return bool;
      }

    };
    
   return Mobile;  
  
})(jQuery);
}).call(this);