/********************************************************************

  New Class Instance 
  Plugin.Twitter Feed
  Return false
  Paramaters false 
  Rob Thiessen - 2016

*********************************************************************/

;(function () {
  
  'use strict'; 
  
  var TwitterFeed = WSI.TwitterFeed = (function () {
      
    function TwitterFeed(){

     var scope = this;
     this.handle = '';
     this.init();

    }
  
    TwitterFeed.prototype = {

      init : function () {
       
        // scope is TwitterFeed "this"
        var scope = this;
        var feed = '/wp-content/themes/wsi/assets/feeds/Twitter.php';
        if (document.location.hostname === "localhost") {
           feed = '/wp-content/themes/wsi/assets/feeds/Twitter.php';
        }

        // Tweet Gallery 
        var tweetGallery = {};

        // set handle 
        this.handle = $('.twitter').data('handle');
      

        $.ajax({
          dataType: 'json',
          url: feed,
          data:{handle:scope.handle},
          success: function (data) {
              $('#twitter-slider').html('');
            for(var i = 0; i < 5; i++ ){
               var tmp = scope.buildTweetMarkup(data[i].text,data[i].created_at);
               $('#twitter-slider').append(tmp);
            }

            $('#twitter-slider').find('a').each(function () {
               $(this).attr('target','_blank');
            });

            $('#twitter-slider').slick({
                infinite: true,
                nextArrow: '',
                prevArrow: '',
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                padding: '20px',
                dots: true,
                autoplay: true,
                autoplaySpeed: 6000,
                accessibility:false,
                customPaging : function(slider, i) {
                    return '<a title="twitter-dots" class="dot"><img alt="paging image" src="/wp-content/themes/wsi/dist/images/twitter-dots.png"></a>';
                },
                responsive: [
                    {
                      breakpoint: 999,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                      }
                    }
                ]             
            });
             
            }
          });
         

      },

      //
      buildTweetMarkup : function (text,time) {
        var t = text.parseURL();
        var h = t.parseHashtag();
        var b = h.parseUsername();
         
        var tmp = '<li>'+
                  '<p>'+b+'</p>'+
                  '<p class="tweet-time">'+this.parseTwitterDate(time)+'</p>' +
                  '</li>';
                return tmp;
      },

      //
      parseTwitterDate:function(tdate) {
        var system_date = new Date(Date.parse(tdate));
        var user_date = new Date();
        if (this.K.ie) {
            system_date = Date.parse(tdate.replace(/( \+)/, ' UTC$1'));
        }
        var diff = Math.floor((user_date - system_date) / 1000);
        if (diff <= 1) {return "just now";}
        if (diff < 20) {return diff + " seconds ago";}
        if (diff < 40) {return "half a minute ago";}
        if (diff < 60) {return "less than a minute ago";}
        if (diff <= 90) {return "one minute ago";}
        if (diff <= 3540) {return Math.round(diff / 60) + " minutes ago";}
        if (diff <= 5400) {return "1 hour ago";}
        if (diff <= 86400) {return Math.round(diff / 3600) + " hours ago";}
        if (diff <= 129600) {return "1 day ago";}
        if (diff < 604800) {return Math.round(diff / 86400) + " days ago";}
        if (diff <= 777600) {return "1 week ago";}
        return "on " + system_date;
    },
    //
    K: function () {
      var a = navigator.userAgent;
      return {
          ie: a.match(/MSIE\s([^;]*)/)
      };
    }



    };
    
   return TwitterFeed;  
  
  })();

}).call(this);

//
String.prototype.parseURL = function() {
  return this.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g, function(url) {
    // console.log( url );
    return url.link(url, "_blank");
  });
};

//
String.prototype.parseHashtag = function() {
  return this.replace(/[#]+[A-Za-z0-9-_]+/g, function(t) {
    var tag = t.replace("#","%23");
    return t.link("https://twitter.com/search/?q="+tag);
  });
};

//
String.prototype.parseUsername = function() {
  return this.replace(/[@]+[A-Za-z0-9-_]+/g, function(u) {
    var username = u.replace("@","");
    return u.link("http://twitter.com/"+username);
  });
};